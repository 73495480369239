@font-face {
  font-family: "Unna";
  src: url(../fonts/Unna-BoldItalic.woff2) format("woff2"),
    url(../fonts/Unna-BoldItalic.woff) format("woff");
  font-weight: bold;
  font-style: normal; /* We are using an italic font even for normal style headings. DONT CHANGE */
}

@font-face {
  font-family: "Ubuntu";
  src: url(../fonts/Ubuntu-Regular.woff2) format("woff2"),
    url(../fonts/Ubuntu-Regular.woff) format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "UbuntuItalic";
  src: url(../fonts/Ubuntu-Italic.woff2) format("woff2"),
    url(../fonts/Ubuntu-Italic.woff) format("woff");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "UbuntuMedium";
  src: url(../fonts/Ubuntu-Medium.woff2) format("woff2"),
    url(../fonts/Ubuntu-Medium.woff) format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "UbuntuMediumItalic";
  src: url(../fonts/Ubuntu-MediumItalic.woff2) format("woff2"),
    url(../fonts/Ubuntu-MediumItalic.woff) format("woff");
  font-weight: 500;
  font-style: italic;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

* {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

html,
body {
  width: 100%;
  height: 100%;
}

body {
  margin: 0px;
  padding: 0px;
  overflow: hidden;
}

#root {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

a {
  text-decoration: none;
}

/* 
table {
  border-collapse: collapse;
}

td {
  border: 1px solid lightgray;
  padding: 0.25rem;
} */
